
















import Component from 'vue-class-component';
import Vue from 'vue';
import {CALENDLY_LINK_TYPE} from '@/shared/constants/CALENDLY_LINK_TYPE';
import {Watch} from 'vue-property-decorator';
import {API_SERVICE} from '@/shared/api/api.service';
import {Action, Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import OnboardingModule from '@/modules/onboarding';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {InterviewGetters} from '@/modules/interview/store/getters';
import InterviewModule from '@/modules/interview';
import {DashboardRouter} from '@/modules/dashboard/router';
import RsLoading from '@/shared/components/loading/loading.vue';

@Component({
  components: {RsLoading}
})
export default class InterviewSchedule extends Vue {
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;
  @Action(InterviewGetters.GET_INTERVIEWS, {namespace: InterviewModule.namespace}) getInterviews: () => void

  type: CALENDLY_LINK_TYPE | null = null;
  calendlyLink: string | null = null;
  baseCalendlyLink: string | null = null;

  loading = true;

  @Watch('$route.params.type', {immediate: true})
  async onRouteChange(type: CALENDLY_LINK_TYPE) {
    this.type = type
    await this.getLink();
  }

  async getLink() {
    if (this.type) {
      const interviewLink = await API_SERVICE.interview.getInterviewLink(this.type);
      this.baseCalendlyLink = interviewLink;
      this.calendlyLink = `${interviewLink}?name=${encodeURIComponent(`${this.user.properties.TALENT_NAME} ${this.user.properties.TALENT_LAST_NAME}`)}&email=${encodeURIComponent(this.user.mainEmail)}`;
      (window as any).Calendly.initInlineWidget({
        url: this.calendlyLink,
        parentElement: document.querySelector('#calendly'),
      });
    }
  }

  async mounted() {
    this.$track.sendEvent('InterviewSchedulePage', {type: this.type});
    window.addEventListener('message', async (e) => {
      if(e.data.event && e.data.event === 'calendly.event_type_viewed'){
        this.loading = false;
      }
      if (e.data.event && e.data.event === 'calendly.event_scheduled') {
        const eventId = e.data.payload.event.uri;
        const inviteeId = e.data.payload.invitee.uri;
        this.$track.sendEvent('InterviewScheduled', {type: this.type});

        await API_SERVICE.interview.markInterviewLinkAsUsed({
          link: this.baseCalendlyLink as string,
          type: this.type as CALENDLY_LINK_TYPE,
          eventId,
          inviteeId,
        });
        this.getInterviews();
        this.$router.push({name: DashboardRouter.INDEX});
      }
    });
  }
}
